<template>
    <b-container>
        <h1>Seaded</h1>
		<hr>
		<template v-if="$store.state.teamData">
			<b-card-group deck>
				<b-card class="text-left">
					<template #header>
						<h4 class="float-left mt-0 mb-0">Perekonnad</h4>
						<b-button variant="primary"
								  class="float-right"
								  @click="addTeam"
						>
							Lisa perekond
						</b-button>
					</template>
					<b-card-text>
						<b-card v-for="(team, idx) in $store.state.teamData.teams"
								:key="idx"
								class="text-left mb-3"
						>
							<template #header>
								<h5 class="float-left">
									{{ team.name }}
									<b-badge v-if="team.uid == $store.state.teamData.current_team.uid" variant="success">
										AKTIIVNE
									</b-badge>
								</h5>
								<b-button variant="primary"
										   class="float-right"
										   @click="editTeam(team)"
								>
									Muuda
								</b-button>
								<b-button
									v-if="team.uid != $store.state.teamData.current_team.uid"
									@click="pickTeam(team.uid)"
									variant="primary"
									class="float-right mr-1"
								>
									Aktiveeri
								</b-button>

							</template>
							<b-card-text>
								<b-row>
									<b-col>
										<b>Pereliikmed:</b><br>
										<ul>
											<li v-for="(member, mIdx) in team.members" :key="mIdx">
												{{ member.text }}
												<b-badge variant="info" v-if="member.transaction_count > 0">
													{{ member.transaction_count }} sissekannet
												</b-badge>
											</li>
										</ul>
										<b>Kasutajad:</b><br>
										<ul>
											<li v-for="(user, uIdx) in team.users" :key="uIdx">
												{{ user.email }}
												<b-badge variant="info" v-if="user.transaction_count > 0">
													{{ user.transaction_count }} sissekannet
												</b-badge>
											</li>
										</ul>
									</b-col>
								</b-row>
								<b-row class="mt-2">
									<b-col>
										<b-button variant="danger"
												  class="float-right mr-1"
												  @click="deleteTeamData(team)"
										>
											Kustuta perekonna andmed
										</b-button>
									</b-col>
								</b-row>
							</b-card-text>
						</b-card>
					</b-card-text>
				</b-card>
				<b-card class="text-left">
					<template #header>
						<h4 class="float-left mt-0 mb-0">Minu andmed</h4>
					</template>
					<b-card-text>
						<b-form-group label-cols="60"
									  label-cols-lg="40"
									  label="Kasutanimi:"
									  label-for="username"
						>
							<b-form-input id="username"
										  v-model="$store.state.user.name"
										  :disabled="!userEditable"
							></b-form-input>
						</b-form-group>
						<b-form-group label-cols="60"
									  label-cols-lg="40"
									  label="Email:"
									  label-for="email"
						>
							<b-form-input id="email"
										  v-model="$store.state.user.email"
										  :disabled="!userEditable"
										  :state="validateEmail"
							></b-form-input>
							<b-form-invalid-feedback :state="validateEmail">
								Ebasobilik emaili aadress.
							</b-form-invalid-feedback>
							<b-form-valid-feedback :state="validateEmail">
								Email OK
							</b-form-valid-feedback>
						</b-form-group>
						<b-link @click="editUser(true)"
								v-if="!passwordEditable"
						>
							Muuda parooli
						</b-link>
						<template v-if="passwordEditable">
							<b-form-group label-cols="60"
										  label-cols-lg="40"
										  label="Praegune parool:"
										  label-for="oldPassword"
							>
								<b-form-input type="password"
											  id="oldPassword"
											  v-model="oldPassword"
											  :disabled="!userEditable"
								></b-form-input>
							</b-form-group>
							<passwords :password.sync="password"
									   :password2.sync="password2"
									   :errors.sync="passwordErrors"
							></passwords>
						</template>

						<b-button variant="primary"
								  class="float-right"
								  @click="editUser(false)"
								  v-if="!userEditable"
						>
							Muuda
						</b-button>
						<b-button variant="primary"
								  class="float-right"
								  @click="saveUser"
								  v-if="userEditable"
								  :disabled="!canSaveUser"
						>
							Salvesta
						</b-button>
						<b-button variant="light"
								  class="float-right mr-1"
								  @click="cancelUserEdit"
								  v-if="userEditable"
						>
							Tühista
						</b-button>
					</b-card-text>
				</b-card>
			</b-card-group>
		</template>

		<b-modal v-model="teamModal.visible"
				 size="lg"
				 title="Muuda perekonda"
				 no-close-on-backdrop
				 hide-footer
		>
			<b-row>
				<b-col>
					<h5>Perekonna nimetus:</h5>
					<b-input type="text" v-model="teamModal.team.name"></b-input>
				</b-col>
			</b-row>
			<hr>
			<b-row>
				<b-col>
					<h5>Pereliikmed:</h5>
					<b-list-group>
						<b-list-group-item v-for="(member, mIdx) in teamModal.team.members" :key="mIdx">
							<template v-if="teamModal.editMember && teamModal.editMember.value == member.value">
								<b-input-group>
									<b-input type="text" v-model="teamModal.editMember.text"></b-input>
									<b-button variant="light"
											  @click="teamModal.editMember=false"
											  size="sm"
									>
										Tühista
									</b-button>
									<b-button variant="success"
											  @click="saveMember(mIdx)"
											  size="sm"
									>
										OK
									</b-button>
								</b-input-group>
							</template>
							<template v-else>
								<b>{{ member.text }}</b> <b-badge variant="info" v-if="member.transaction_count > 0">{{ member.transaction_count }} sissekannet</b-badge>
								<b-button variant="primary"
										  class="float-right"
										  @click="editMember(member)"
										  size="sm"
								>
									<b-icon icon="pencil"></b-icon>
								</b-button>
								<b-button v-if="member.transaction_count == 0"
										  variant="danger"
										  class="float-right mr-1"
										  @click="deleteMember(mIdx)"
										  size="sm"
								>
									<b-icon icon="trash"></b-icon>
								</b-button>
							</template>
						</b-list-group-item>
						<b-list-group-item>
							<b-input-group>
								<b-input type="text" v-model="teamModal.addMember"></b-input>
								<b-button variant="primary" @click="addMember">
									Lisa pereliige
								</b-button>
							</b-input-group>
						</b-list-group-item>
					</b-list-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>

				</b-col>
			</b-row>
			<b-row>
				<b-col class="text-right">
					<hr>
					<b-button
						variant="light"
						@click="teamModal.visible = false"
					>
						Tühista
					</b-button>
					<b-button
						variant="primary"
						class="ml-2"
						@click="saveTeam"
						:disabled="teamModal.team.name == ''"
					>
						Salvesta
					</b-button>
				</b-col>
			</b-row>
		</b-modal>

    </b-container>
</template>

<script>

	import Swal from "sweetalert2";
	import axios from "axios";
	import Passwords from '@/components/Passwords';

	export default {
		components: {
			Passwords
		},
		data () {
			return {
				userEditable: false,
				passwordEditable: false,
				userOriginal: false,
				oldPassword: '',
				password: '',
				password2: '',
				passwordErrors: false,
				teamModal: {
					visible: false,
					team: {
						members: [],
						uid: false,
						name: false,
					},
					addMember: '',
					editMember: false,
				}
			}
		},
		created() {
			if (this.$store.state.teamData) {
				this.$store.dispatch('loadTeamData', this.$store.state.teamData.current_team.uid);
			}
		},
		computed: {
			validateEmail: function() {
				if (!this.userEditable) {
					return null;
				}
				const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
				return isEmail.test(this.$store.state.user.email);
			},
			canSaveUser: function() {
				if (!this.validateEmail) {
					return false;
				}
				if (this.$store.state.user.name == '' || this.$store.state.user.email == '') {
					return false;
				}
				// check for passwords if they are editable
				if (this.passwordErrors || this.oldPassword == '') {
					return false;
				}
				return true;
			}
		},
		methods: {
			login () {
				this.$store
					.dispatch('login', {
						email: this.email,
						password: this.password
					})
					.then(() => {
						this.$router.push({ name: 'Transactions' })
					})
					.catch(err => {
						console.log(err)
					})
			},
			pickTeam (teamUid) {
				this.$store.dispatch('pickTeam', teamUid);
			},
			addTeam() {
				this.teamModal.visible = true;
				this.teamModal.team = {
					members: [],
					name: '',
					uid: false,
				}
			},
			editTeam(team) {
				this.teamModal.visible = true;
				this.teamModal.team = JSON.parse(JSON.stringify(team));
			},
			addMember() {
				this.teamModal.team.members.push({
					text: this.teamModal.addMember,
					value: false,
					transaction_count: 0,
				});
				this.teamModal.addMember = '';
			},
			deleteMember(idx) {
				this.teamModal.team.members.splice(idx, 1);
			},
			editMember(member) {
				this.teamModal.editMember = JSON.parse(JSON.stringify(member));
			},
			saveMember(idx) {
				this.teamModal.team.members[idx].text = this.teamModal.editMember.text;
				this.teamModal.editMember = false;
			},
			saveTeam() {
				axios.post('/team_data', this.teamModal.team).then(({data}) => {
					this.teamModal.visible = false;
					this.$store.dispatch('loadTeamData', data);
					Swal.fire({
						title: 'Salvestatud',
						confirmButtonText: 'OK',
						confirmButtonColor: '#6f42c1',
						icon: 'success',
					});
				});
			},
			editUser(withPassword) {
				withPassword ? this.passwordEditable = true : false;
				this.userEditable = true;
				this.userOriginal = JSON.parse(JSON.stringify(this.$store.state.user));
				this.password = '';
				this.password2 = '';
				this.oldPassword = '';
			},
			cancelUserEdit() {
				this.userEditable = false;
				this.passwordEditable = false;
				this.$store.state.user.name = this.userOriginal.name;
				this.$store.state.user.email = this.userOriginal.email;
			},
			saveUser() {
				let postData = {
					user: this.$store.state.user,
					oldPassword: false,
					password: false,
				}
				if (this.passwordEditable) {
					postData.oldPassword = this.oldPassword;
					postData.password = this.password;
				}
				axios.post('/user', postData).then(({data}) => {
					this.$store.dispatch('setupUser', data);
					this.userEditable = false;
					this.passwordEditable = false;
					Swal.fire({
						title: 'Salvestatud',
						confirmButtonText: 'OK',
						confirmButtonColor: '#6f42c1',
						icon: 'success',
					});
				});
			},
			deleteTeamData(team) {
				Swal.fire({
					title: 'Soovid perekonna andmed kustutada?',
					text: 'Kõik perekonna liikmed, kategooriad ja sissekanded kustutatakse!',
					icon: 'error',
					showCancelButton: true,
					confirmButtonColor: '#6f42c1',
					confirmButtonText: 'Kustuta',
				}).then((result) => {
					if (result.isConfirmed) {
						Swal.fire({
							title: 'Soovid tõesti KÕIK perekonna andmed kustutada?',
							text: 'Kõik perekonna liikmed, kategooriad ja sissekanded kustutatakse! Taastamine pole peale seda võimalik!',
							icon: 'error',
							showCancelButton: true,
							confirmButtonColor: 'red',
							confirmButtonText: 'Kustuta',
						}).then((result) => {
							if (result.isConfirmed) {
								axios.delete('/team_data/'+team.uid).then(({data}) => {
									this.$store.dispatch('loadTags', data);
									this.$store.dispatch('loadRules', data);
									this.$store.dispatch('loadTeamData', team.uid);
									Swal.fire({
										title: 'Perekonna andmed kustutatud',
										confirmButtonText: 'OK',
										confirmButtonColor: '#6f42c1',
										icon: 'success',
									});
								});
							}
						});
					}
				});
			}
		}
	}
</script>
